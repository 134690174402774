import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "There Is Value In Mentorship",
  "author": "Christina",
  "date": "2020-01-28T08:05:38.000Z",
  "categories": ["mentorship", "Personal-Professional Growth"],
  "slug": "there-is-value-in-mentorship",
  "draft": false,
  "meta_title": "There Is Value In Mentorship",
  "cover": "../../images/wp_blog_images/life-belt-498453_1920.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I did not have a mentor until about six years ago. This probably sounds insane to some, especially if I mention that I was in the military for 11 years, separating almost 12 years ago. `}</p>
    <p>{`I never understood the importance of mentorship. From an outsider's perspective, it just looked like the mentor was using their status to benefit someone they favored. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.5625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABBP/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB1UDoztWOk//EABwQAAICAgMAAAAAAAAAAAAAAAABAgMEExESIf/aAAgBAQABBQKElPL8OUU1do6TUkf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwFrSH//xAAcEAABAwUAAAAAAAAAAAAAAAAAAQJBEBESIjL/2gAIAQEABj8CarZTauVzog//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhMWFBodH/2gAIAQEAAT8hQ3GNsaWiHkhKW9Ck76ECLbafp//aAAwDAQACAAMAAAAQm8//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFx/9oACAEDAQE/EDYNxf/EABcRAQADAAAAAAAAAAAAAAAAAAABQXH/2gAIAQIBAT8QpS0//8QAHBAAAgMAAwEAAAAAAAAAAAAAAREAITFRcYHh/9oACAEBAAE/EDlGUnAYL5wS8FHtw1kEQJuZ9QtoXWmPXAMRI1CY/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "life belt 498453 1920",
          "title": "life belt 498453 1920",
          "src": "/static/1cfc628ebe6871c2686948982cc2f72a/eea4a/life-belt-498453_1920.jpg",
          "srcSet": ["/static/1cfc628ebe6871c2686948982cc2f72a/cb69c/life-belt-498453_1920.jpg 320w", "/static/1cfc628ebe6871c2686948982cc2f72a/c08c5/life-belt-498453_1920.jpg 640w", "/static/1cfc628ebe6871c2686948982cc2f72a/eea4a/life-belt-498453_1920.jpg 1280w", "/static/1cfc628ebe6871c2686948982cc2f72a/0f98f/life-belt-498453_1920.jpg 1920w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` Image by Picography from Pixabay\\`}</p>
    <p>{`However, I learned a valuable lesson from my first ever mentor. I learned that I have no idea how anyone else’s relationship is with others, even if they tell me, and I should not concern myself with it. I learned to focus on my relationships and my life a little more every year under this wise person’s guidance. `}</p>
    <p>{`For five years, I was mentored for the first time ever. To be honest, I didn't even know I was being mentored at first. It was a beautiful, challenging, and sometimes annoying relationship. I did not always like what he had to say and he did not always like that I did not follow his guidance 100 percent. `}</p>
    <p>{`It was tough when I realized I outgrew my mentor. Just like you will never forget your first love-you will never forget your first mentor. I clung to him for dear life-believing I would drown without the security of his guidance. Little by little, he became less invested and less available to me. `}</p>
    <p>{`It hurt. But, it was necessary. He understands me and knew that he could not tell me I no longer needed him the way I once did. He could not tell me enough that not only would I swim without him, but I would fly. `}</p>
    <p>{`I now understand you cannot know the value of mentorship until you have experienced it. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      